@import '../../assets/styles/global';

.contact-form {
  padding: 100px 0;
  color: $dark-green;

  .contact--container {
    display: flex;
    gap: 40px;
    overflow-x: hidden;

    @include mobile {
      flex-wrap: wrap;
    }

    .left--container {
      position: relative;
      overflow: hidden;

      @include desktop {
        width: 45%;
      }

      h2 {
        @include mobile {
          width: 100%;
          text-align: center;
        }
      }

      .submit-confirmation {
        font-size: 1.5rem;
      }
    }
  }
  
  form {
    width: 100%;

    @include desktop {
      width: 55%;
    }

    .error-message {
      margin-top: 10px;
      color: $dark-corail;
    }

    label {
      font-size: 1.5rem;
      font-weight: 500;
    }

    .textfield--container {
      margin-bottom: 15px;
      font-size: 1.2rem;
      font-weight: 600;
    }

    input, textarea {
      width: 100%;
      height: 40px;
      border: 1px solid $dark-green;
      border-radius: 15px;
      padding: 15px;
      outline: none !important;
      margin-top: 10px;
      color: $dark-green;
      font-size: 1.5rem;
    }

    textarea {
      height: 200px;
      resize: none;
    }
  }
}