@import '../../assets/styles/global';

.menu {
  @include desktop {
    background-color: transparent;
    z-index: 2;
    
    ul {
      display: flex;
      gap: 50px;
      list-style: none;
      font-size: 1.7rem;
      margin-top: 50px;
  
      .secondary-link {
        position: relative;
        padding-bottom: 3px;
      }
  
      .secondary-link:after {
        transition: all ease-in-out .2s;
        background-color: $white;
        content: "";
        height: 2px;
        width: 0;
        bottom: 0;
        left: 0;
        position: absolute;
      }
  
      .secondary-link:hover:after {
        width: 100%;
      }
    }
  }

  @include mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 6;
    background-color: $medium-green;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .social-networks__container {
      display: flex;
      justify-content: center;
      gap: 30px;

      .icon-link {
        font-size: 5rem;
        color: $beige;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      flex: 1 0;
      justify-content: center;

      .menu-item {
        position: relative;
        font-size: 3rem;
        list-style: none;
        width: 100%;
        height: 70px;
        text-align: center;

        &:hover {
          background-color: $dark-green;
          cursor: pointer;
        }

        a {
          position: absolute;
          top: 20%;
          left: 0;
          font-weight: 500;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.close {
      width: 0%;

      ul, .social-networks__container {
        display: none;
      }
    }

    &.open {
      transform: translate(0px);
      visibility: visible;
    }
  }
}

.menu-icon {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 7;
  font-size: 3rem;
  color: $medium-green;
  background-color: transparentize($white, 0.4);
  height: 45px;
  width: 45px;
  padding: 5px;
  border-radius: 5px;
  border: none;
}