@import '../../assets/styles/global';

.projects__container {
  color: $dark-green;
  padding: 100px 0;

  .limited-content {
    display: flex;
    margin: auto;
    gap: 150px;

    @include below($lg) {
      flex-direction: column;
      gap: 30px;
    }

    .projects-title {
      @include above($lg) {
        width: 40%;
        margin: 0 auto;
      }

      p {
        font-size: 2.2rem;
        line-height: 3.5rem;
      }
    }
  }

  h2 {
    margin-bottom: 30px;
  }
}