@import '../../assets/styles/global';

.projects-slider {
  overflow-x: hidden;

  @include above($lg) {
    max-width: 60%;
  }

  .slideshow--buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 10px;

    button {
      cursor: pointer;
    }
    
    .iconify {
      font-size: 3rem;
    }
  }

  ul {
    white-space: nowrap;
    transition: ease 1000ms;
    
    .slide {
      position: relative;
      display: inline-block;
      gap: 10px;
      width: 100%;
      height: 400px;
      border-radius: 40px;

      @include mobile {
        height: 300px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      .modal-hover {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: ease-in-out 0.5s;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include desktop {
          opacity: 0;
        }

        @include mobile {
          background-color: transparentize($white, 0.5);

          h3 {
            display: none;
          }

          .btn.secondary {
            width: unset;
          }
        }

        &:hover {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: transparentize($white, 0.5);
          cursor: pointer;
          opacity: 1;
        }

        h3 {
          margin-bottom: 35px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}