@import './variables';
@import './default.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'OpenSans';
}

html {
	font-size: 10px;
}

body {
  overflow-x: hidden;
}

.green-background {
  background-color: $medium-green;
}

.beige-background {
  background-color: $beige;
}

.limited-content {
  margin: 0 auto;
  width: 75%;

  @include mobile {
    width: 100%;
    padding: 0 20px;
  }
}

h1 {
  position: relative;
  font-size: 6rem;
  font-weight: 500;
  width: fit-content;

  @include mobile {
    font-size: 4rem;
  }
}

h2 {
  font-size: 3.6rem;

  @include mobile {
    font-size: 3rem;
  }
}

p {
  font-size: 2.6rem;
  line-height: 4.2rem;

  @include mobile {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.btn--dark-green {
  border: none;
  padding: 10px 20px;
  color: $white;
  border-radius: 20px;
}

.btn--transparent {
  border: none;
  background-color: transparent;
  color: $dark-green;
}

h3 {
  color: $dark-green;
  text-transform: uppercase;
  font-size: 2.5rem;
}

a.primary-link {
  color: $dark-green;
  font-weight: 600;
}

a.secondary-link {
  color: $white;
  text-decoration: none;
  font-weight: 600;
}

.desktop {
  @include mobile {
    display: none;
  }
}

.mobile {
  @include desktop {
    display: none;
  }
}