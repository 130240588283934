/* custom fonts */
@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Light.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: 600;
}

$xl: 1140px;
$lg: 1140px;
$md: 992px;
$sm: 574px;

@mixin desktop {
  @media screen and (min-width: $md) {
    &{ @content; }
  }
}

@mixin mobile {
  @media screen and (max-width: $md) {
    &{ @content; }
  }
}

@mixin below($below) {
  @media screen and (max-width: $below) {
    &{ @content; }
  }
}

@mixin above($above) {
  @media screen and (min-width: $above) {
    &{ @content; }
  }
}