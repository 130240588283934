@import '../../assets/styles/global';

.modal.open {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;

  .modal--content {
    position: absolute;
    display: block;
    width: 70%;
    height: 70%;
    left: 15%;
    top: 15%;
    margin: auto;
    background-color: $beige;
    color: $dark-green;
    padding: 50px 20px 20px;
    font-size: 2rem;
    border-radius: 10px;
    overflow-y: auto;

    @include below($lg) {
      width: 90%;
      height: 90%;
      left: 5%;
      top: 5%;
    }
    
    h3 {
      margin-bottom: 10px;
      letter-spacing: 0.2rem;
    }

    a {
      color: $medium-green;
      font-weight: 600;
    }

    .date {
      font-size: 1.6rem;
    }

    .btn--transparent {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;

      @include mobile {
        width: 50px;
      }

      .iconify  {
        font-size: 2.5rem;
      }
    }

    .projects-slider {
      max-width: 70%;
      margin: 0 auto;

      .slide {
        height: 200px;
      }

      @include mobile {
        max-width: 100%;

        img {
          height: 200px;
        }
      }
    }

    .technologies {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .description {
      margin-bottom: 20px;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 400px;
      margin-top: 40px;
    }
  }
}

body:has(.modal.open) {
  overflow: hidden;
}

.modal.close {
  display: none;
}