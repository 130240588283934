@import '../../assets/styles/global';

.social-networks__container {
  @include desktop {
    position: fixed;
    right: 0;
    top: 50vh;
    display: flex;
    flex-direction: column;
    z-index: 4;
    background-color: transparentize($white, 0.3);
    padding: 10px;

    .icon-link {
      font-size: 3rem;
      color: $medium-green;
    }
  }

  @include mobile {
    display: none;
  }
}