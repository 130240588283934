@import '../../assets/styles/global';

footer {
  color: $white;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $medium-green;
  width: 100%;
  height: 100px;
}