$white: #fff;

$medium-green: #6c9d77;
$dark-green: #58795f;
$light-green: #519758;
$lighter-green: #5ead60;

$beige: #f7ebdc;

$corail: #e37d64;
$dark-corail: #d5745c;
