@import '../../assets/styles/global';

.btn.primary, .btn-link.primary {
  background-color: $corail;
  
  &:hover {
    background-color: $dark-corail;
  }
}

.btn.secondary, .btn-link.secondary {
  background-color: $medium-green;

  &:hover {
    background-color: $dark-green;
  }
}

.btn-link {
  text-decoration: none;
}

.btn, .btn-link {
  border: none;
  padding: 10px 20px;
  font-size: 1.5rem;
  transition: .3s ease-in-out;
  border-radius: 20px;
  font-weight: 500;
  color: $white;

  &.btn--round {
    background-color: $medium-green;
    border-radius: 100%;
    color: $white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
  
    &:hover {
      background-color: $dark-green;
    }
  }

  @include below($sm) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }

  .spinner {
    display: inline-block;
    width: 30px;
    height: 15px;

    &:after {
      content: " ";
      display: block;
      width: 15px;
      height: 15px;
      margin-left: 10px;
      border-radius: 50%;
      border: 3px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: spinner 1.2s linear infinite;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}