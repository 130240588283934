@import '../../assets/styles/global';

.card--content {
  position: relative;
  padding: 20px;
  background-color: $beige;
  width: 47%;
  border-radius: 15px;

  h3 {
    font-size: 2rem;
    margin-bottom: 10px,
  }

  .surtitle {
    font-size: 1.5rem;
    color: $dark-green;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}