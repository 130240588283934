@import '../../assets/styles/global';

.light-green--background {
  position: relative;
  background-color: $medium-green;
  width: 100%;
  height: 100%;
}

.experiences {
  padding: 100px 0;

  h2 {
    color: $beige;
    margin-bottom: 50px;
  }

  .card:not(:last-child) {
    margin-bottom: 50px;
  }

  .card--content {
    @include below($lg) {
      &:after {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $beige;
        position: absolute;
        left: -58px;
        top: 15px;
      }
    }
  }

  @include below($lg) {
    .card {
      margin-left: 200px;
    }
  }

  @include mobile {
    .card {
      margin-left: 70px;
    }

    .card--content {
      width: calc(100% - 20px) !important;
    }
  }

  @include above($lg) {
    .card:nth-child(2n+1) {
      .card--content {
        &:before {
          content: '';
          position: absolute;
          display : inline-block;
          top: 15px;
          height : 0;
          width : 0;
          border-top : 10px solid transparent;
          border-bottom : 10px solid transparent;
          left: -10px;
          border-right : 10px solid $beige;
        }
  
        &:after {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $beige;
          position: absolute;
          left: -38px;
          top: 15px;
        }
      }
    }
  
    .card:nth-child(2n) {
      .card--content {
        &:after {
          content: '';
          position: absolute;
          display : inline-block;
          top: 15px;
          height : 0;
          width : 0;
          border-top : 10px solid transparent;
          border-bottom : 10px solid transparent;
          right: -10px;
          border-left : 10px solid $beige;
        }
  
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $beige;
          position: absolute;
          right: -42px;
          top: 15px;
        }
      }
    }
  }


  .card--content {
    position: relative;
    padding: 20px;
    background-color: $beige;
    width: 47%;
    border-radius: 15px;

    h3 {
      font-size: 2rem;
      margin-bottom: 10px,
    }

    .date {
      font-size: 1.5rem;
      color: $dark-green;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .description {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  .experiences--container {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      width: 4px;
      background-color: $beige;

      @include below($lg) {
        left: 15%;
      }

      @include mobile {
        left: 20px;
      }
    }
  }

  @include above($lg) {
    .card:nth-child(2n+1) {
      display: flex;
      justify-content: flex-end;
    }
  }
}
