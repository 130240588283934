@import '../../assets/styles/global';

.header {
  height: 100vh;
  width: 100%;
  color: $white;

  .separator {
    margin-top: 20px;
    width: 220px;
    height: 1px;
    background-color: $white;

    @include mobile {
      margin: 0 auto;
    }
  }

  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @include mobile {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      h1 {
        width: 100%;
      }
    }
  
    .job {
      line-height: 5.5rem;
      margin-top: 30px;
      font-size: 5rem;

      @include desktop {
        max-width: 450px;
      }
    }

    .description {
      flex: 1 0;
      display: flex;
      align-items: center;
      gap: 20%;
      z-index: 5;
      
      .hook {
        margin: 20px 0 30px;
        max-width: 80%;

        @include mobile {
          max-width: 100%;
        }
      }
    }
  }

  .down-icon {
    display: flex;
    justify-content: center;

    a {
      color: $white;
      font-size: 5rem;
      animation: 2s flash infinite;
      transition: none;
  
    }
  }

  .wave {
    position: absolute;
    top: 0;
    right: 0;
    height: 215px;
    width: 600px;
    background: $beige;
    border-bottom-left-radius: 120%;

    @include below($lg) {
      display: none;
    }
  }

  .wave:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 30% 100%;
    width: 425px;
    height: 389px;
    background-color: $beige;
    right: -74px;
    top: 0px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    animation: 2s ease-in-out 0s 1 wavingEffect;
  }

  .wave:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 0% 100%;
    width: 290px;
    height: 300px;
    background-color: $medium-green;
    left: -3px;
    top:  -2px;
    transition: all 0.3s ease-in-out;
    animation: 2s ease-in-out 0s 1 wavingEffect2;
  }
}

@keyframes wavingEffect {
  0% {
    height: 398px;
    width: 425px;
  }
  50% {
    height: 405px;
    width: 430px;
  }
  100% {
    height: 398px;
    width: 425px;
  }
}

@keyframes wavingEffect2 {
  0% {
    height: 300px;
  }
  50% {
    height: 315px;
  }
  100% {
    height: 300px;
  }
}

@keyframes flash {
  0%   { opacity:1; }
  40%   {opacity:0; }
  100% { opacity:1; }
}